.App {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

#TableAttempts .ant-table-selection {
  display: none;
}

