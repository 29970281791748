.login_form {
    display: table;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding: 5rem 10rem;
    border: solid #ccc 1px;
    background-color: #FFF;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    border: 0px solid #000000;
}
.login_form_forgot {
    float: right;
}
.login_form_button {
    width: 100%;
}

.Form_Image {
    width : 150px;
}

.Form_Item {
    width: 20rem;
}

@media (max-width: 800px) {
    .login_form {
        max-width: 800px;
        padding: 5rem 7rem;
    }

    .Form_Image {
        width : 150px;
    }
    
    .Form_Item {
        width: 18rem;
    }
}

@media (max-width: 600px) {
    .login_form {
        max-width: 500px;
        padding: 3rem 5rem;
    }

    .Form_Image {
        width : 130px;
    }
    
    .Form_Item {
        width: 16rem;
    }
}

@media (max-width: 550px) {
    .login_form {
        max-width: 300px;
        padding: 2.5rem 4rem;
    }

    .Form_Image {
        width : 120px;
    }
    
    .Form_Item {
        width: 15rem;
    }
}

@media (max-width: 450px) {
    .login_form {
        max-width: 300px;
        padding: 2rem 3rem;
    }

    .Form_Image {
        width : 100px;
    }
    
    .Form_Item {
        width: 13rem;
    }
}

@media (max-width: 350px) {
    .login_form {
        max-width: 300px;
        padding: 2rem 3rem;
    }

    .Form_Image {
        width : 100px;
    }
    
    .Form_Item {
        width: 10rem;
    }
}

@media (max-width: 300px) {
    .login_form {
        max-width: 200px;
        padding: 1.5rem 2.5rem;
    }

    .Form_Image {
        width : 100px;
    }
    
    .Form_Item {
        width: 10rem;
    }
}




